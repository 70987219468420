import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import service from "@/api/admin/blog";
import categoryservice from "@/api/admin/category";
import tagService from "@/api/admin/tag";
import router from "@/router";
import tools from "@/utils/tools";
export default defineComponent({
  setup() {
    const state = reactive({
      table: {
        search: {
          blogName: "",
          tagName: "",
          categoryName: ""
        },
        page: 1,
        size: 10,
        total: 0,
        data: [],
        loading: false,
        categoryItem: [],
        tagItem: []
      }
    });
    const methods = {
      findList() {
        state.table.loading = true;
        service.findList(state.table.page, state.table.size, state.table.search).then(res => {
          state.table.data = res.data.data;
          state.table.page = res.data.page;
          state.table.size = res.data.size;
          state.table.total = res.data.total;
        }).finally(() => {
          state.table.loading = false;
        });
      },

      findSimpleCategory() {
        categoryservice.getSimpleCategory().then(res => {
          state.table.categoryItem = res.data;
        });
      },

      findSimpleTag() {
        tagService.findSimpleTag().then(res => {
          state.table.tagItem = res.data;
        });
      },

      deleteBlog(id) {
        if (id) {
          service.deleteBlog([id]).then(res => {
            if (res.code === 200) {
              tools.message("删除成功!", "success");
              methods.findList();
            }
          });
        }
      },

      handlePageChange() {
        methods.findList();
      },

      HandlerTag(tags) {
        return tags.split(",");
      },

      onReset() {
        state.table.search.blogName = "";
        state.table.search.tagName = "";
        state.table.search.categoryName = "";
        methods.findList();
      },

      goBlog(id) {
        router.push("/admin/edit/" + id);
      }

    };
    onMounted(() => {
      methods.findList();
      methods.findSimpleCategory();
      methods.findSimpleTag();
    });
    return { ...toRefs(state),
      ...methods
    };
  }

});